import React from "react";
import Header from '../../components/Main/Header';
import Mission from '../../components/Mission';
import ReviewUserCard from '../../components/ReviewUserCard';
import Footer from "../../components/Main/Footer";
import Frequently from "../../components/Frequently";
import Packages from '../../components/Packages';
import RegisteredShops from '../../components/RegisteredShops';
import Contact from './Contact';
import SectionFour from './SectionFour';
import Payment from '../Payment';
import OffcanvasExample from '../../components/Main/NavBar';


const Home = ({ toggleDarkMode, toggleDarkTheme }) => {
    return (

        <>
            <OffcanvasExample toggleDarkMode={toggleDarkMode} toggleDarkTheme={toggleDarkTheme} />
            <Header  toggleDarkMode={toggleDarkMode} toggleDarkTheme={toggleDarkTheme}/>
            <Mission toggleDarkMode={toggleDarkMode} toggleDarkTheme={toggleDarkTheme} />
            <RegisteredShops toggleDarkMode={toggleDarkMode} toggleDarkTheme={toggleDarkTheme}/>
            <Payment toggleDarkMode={toggleDarkMode} toggleDarkTheme={toggleDarkTheme} />
            <ReviewUserCard toggleDarkMode={toggleDarkMode} toggleDarkTheme={toggleDarkTheme}/>
            <SectionFour toggleDarkMode={toggleDarkMode} toggleDarkTheme={toggleDarkTheme}/>
            <Packages  toggleDarkMode={toggleDarkMode} toggleDarkTheme={toggleDarkTheme}/>
            <Frequently toggleDarkMode={toggleDarkMode} toggleDarkTheme={toggleDarkTheme}/>
            <Contact toggleDarkMode={toggleDarkMode} toggleDarkTheme={toggleDarkTheme}/>
            <Footer toggleDarkMode={toggleDarkMode} toggleDarkTheme={toggleDarkTheme} />
        </>
        
    );
};

export default Home;
