import React, { useContext, useState, useEffect } from "react";
import '.././RegisteredShops.css';
import img1 from '../../assets/section4.png';
import logo1 from '../../assets/playstore 1.png';
import appStore from '../../assets/app-btn.png';
import googlePlay from '../../assets/google-btn.png';
import { LanguageContext } from ".././Main/LanguageContext";
import translations from "../../Translations";




const SectionFour = () => {
    const { language } = useContext(LanguageContext);
    const t = translations[language];
    const langClass = language === 'ar' ? 'arabic' : 'english';

    const [isResponsive, setIsResponsive] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsResponsive(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className={`shop_container ${langClass}`}>
            <div className='container'>
                <div className={`shop_cont ${isResponsive ? 'column' : ''}`}>
                    {isResponsive ? (
                        <>
                        <div className="first">
                        <div className={`logo-cta ${langClass}`}>
                                <img src={logo1} alt="Logo" className="logo-img-shop" />
                            </div>
                            <h2 className='text-buyer marginTop'>{t.sectionFour2}</h2>
                        </div>
                
                            <p className={`text-shops ${langClass}`}>{t.paraSectionFour}</p>
                            <div className="image-section">
                                <img src={img1} alt="" />
                            </div>

                            <div className={`cta ${langClass}`}>
                                <a href="https://apps.apple.com/ly/app/libyashop/id6477825495"><img src={appStore} alt="App Store" className="cta-button1" /></a>
                                <a href="https://play.google.com/store/apps/details?id=com.libyashop.ebuyer&hl=en&gl=US&fbclid=IwZXh0bgNhZW0CMTAAAR2SpE-A5UAatqNtD4DTbQ8CNj72Klku9_bX00-_LGDJFtQ9oXvFcP-Hgp8_aem_raPF6CXz_pXuQtC-tVX3sQ"><img src={googlePlay} alt="Google Play" className="cta-button2" /></a>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={`text-section top-text ${langClass}`}>
                                <div className={`logo-cta ${langClass}`}>
                                    <img src={logo1} alt="Logo" className="logo-img-shop" />
                                </div>
                                <h2 className='text-buyer'> {t.sectionFour2}</h2>
                                <p className={`text-shops ${langClass}`}>{t.paraSectionFour}</p>
                                <div className={`cta ${langClass}`}>
                                    <a href="https://apps.apple.com/ly/app/libyashop/id6477825495"><img src={appStore} alt="App Store" className="cta-button1" /></a>
                                    <a href="https://play.google.com/store/apps/details?id=com.libyashop.ebuyer&hl=en&gl=US&fbclid=IwZXh0bgNhZW0CMTAAAR2SpE-A5UAatqNtD4DTbQ8CNj72Klku9_bX00-_LGDJFtQ9oXvFcP-Hgp8_aem_raPF6CXz_pXuQtC-tVX3sQ"><img src={googlePlay} alt="Google Play" className="cta-button2" /></a>
                                </div>
                            </div>
                            <div className="image-section special-img">
                                <img src={img1} alt="" />
                            </div>

                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default SectionFour;


// import React, { useContext, useState, useEffect } from "react";
// import '.././RegisteredShops.css';
// import img1 from '../../assets/section4.png';
// import logo1 from '../../assets/playstore 1.png';
// import appStore from '../../assets/app-btn.png';
// import googlePlay from '../../assets/google-btn.png';
// import { LanguageContext } from ".././Main/LanguageContext";
// import { useTheme } from ".././Main/ThemeContext";
// import translations from "../../Translations";

// const SectionFour = () => {
//     const { language } = useContext(LanguageContext);
//     const { theme, toggleTheme } = useTheme(); 
//     const t = translations[language];
//     const langClass = language === 'ar' ? 'arabic' : 'english';

//     const [isResponsive, setIsResponsive] = useState(window.innerWidth <= 768);

//     useEffect(() => {
//         const handleResize = () => {
//             setIsResponsive(window.innerWidth <= 768);
//         };

//         window.addEventListener('resize', handleResize);
//         return () => window.removeEventListener('resize', handleResize);
//     }, []);

//     return (
//         <div className={`shop_container ${langClass} ${theme}`}>
//             <div className='container'>
//                 <div className={`shop_cont ${isResponsive ? 'column' : ''}`}>
//                     {isResponsive ? (
//                         <>
//                         <div className="first">
//                         <div className={`logo-cta ${langClass}`}>
//                                 <img src={logo1} alt="Logo" className="logo-img-shop" />
//                             </div>
//                             <h2 className='text-buyer marginTop'>{t.sectionFour2}</h2>
//                         </div>
                
//                             <p className={`text-shops ${langClass}`}>{t.paraSectionFour}</p>
//                             <div className="image-section">
//                                 <img src={img1} alt="" />
//                             </div>

//                             <div className={`cta ${langClass}`}>
//                                 <a href="https://apps.apple.com/ly/app/libyashop/id6477825495"><img src={appStore} alt="App Store" className="cta-button1" /></a>
//                                 <a href="https://play.google.com/store/apps/details?id=com.libyashop.ebuyer&hl=en&gl=US&fbclid=IwZXh0bgNhZW0CMTAAAR2SpE-A5UAatqNtD4DTbQ8CNj72Klku9_bX00-_LGDJFtQ9oXvFcP-Hgp8_aem_raPF6CXz_pXuQtC-tVX3sQ"><img src={googlePlay} alt="Google Play" className="cta-button2" /></a>
//                             </div>
//                         </>
//                     ) : (
//                         <>
//                             <div className={`text-section top-text ${langClass}`}>
//                                 <div className={`logo-cta ${langClass}`}>
//                                     <img src={logo1} alt="Logo" className="logo-img-shop" />
//                                 </div>
//                                 <h2 className='text-buyer'> {t.sectionFour2}</h2>
//                                 <p className={`text-shops ${langClass}`}>{t.paraSectionFour}</p>
//                                 <div className={`cta ${langClass}`}>
//                                     <a href="https://apps.apple.com/ly/app/libyashop/id6477825495"><img src={appStore} alt="App Store" className="cta-button1" /></a>
//                                     <a href="https://play.google.com/store/apps/details?id=com.libyashop.ebuyer&hl=en&gl=US&fbclid=IwZXh0bgNhZW0CMTAAAR2SpE-A5UAatqNtD4DTbQ8CNj72Klku9_bX00-_LGDJFtQ9oXvFcP-Hgp8_aem_raPF6CXz_pXuQtC-tVX3sQ"><img src={googlePlay} alt="Google Play" className="cta-button2" /></a>
//                                 </div>
//                             </div>
//                             <div className="image-section special-img">
//                                 <img src={img1} alt="" />
//                             </div>

//                         </>
//                     )}
//                 </div>
//             </div>
//             <button onClick={toggleTheme}>Toggle Theme</button> 
//         </div>
//     );
// }

// export default SectionFour;



// import React, { useContext, useState, useEffect } from "react";
// import { Card, CardContent, CardMedia, Typography } from "@mui/material";
// import { LanguageContext } from ".././Main/LanguageContext";
// import { useTheme } from "@mui/material/styles";
// import translations from "../../Translations";
// import '.././RegisteredShops.css';
// import img1 from '../../assets/section4.png';
// import logo1 from '../../assets/playstore 1.png';
// import appStore from '../../assets/app-btn.png';
// import googlePlay from '../../assets/google-btn.png';

// const SectionFour = () => {
//     const { language } = useContext(LanguageContext);
//     const theme = useTheme(); //  MUI theme
//     const t = translations[language];
//     const langClass = language === 'ar' ? 'arabic' : 'english';

//     const [isResponsive, setIsResponsive] = useState(window.innerWidth <= 768);

//     useEffect(() => {
//         const handleResize = () => {
//             setIsResponsive(window.innerWidth <= 768);
//         };

//         window.addEventListener('resize', handleResize);
//         return () => window.removeEventListener('resize', handleResize);
//     }, []);

//     return (
//         <Card className={`shop_container ${langClass}`} sx={{ backgroundColor: theme.palette.background.default }}>
//             <CardContent className="container">
//                 <div className={`shop_cont ${isResponsive ? 'column' : ''}`}>
//                     {isResponsive ? (
//                         <>
//                             <div className="first">
//                                 <div className={`logo-cta ${langClass}`}>
//                                     <img  src={logo1} alt="Logo" className="logo-img-shop" />
//                                 </div>
//                                 <Typography variant="h4" className='text-buyer marginTop'>{t.sectionFour2}</Typography>
//                             </div>

//                             <Typography variant="body1" className={`text-shops ${langClass}`}>
//                                 {t.paraSectionFour}
//                             </Typography>
//                             <div className="image-section">
//                                 <img  src={img1} alt="" />
//                             </div>

//                             <div className={`cta ${langClass}`}>
//                                 <a href="https://apps.apple.com/ly/app/libyashop/id6477825495"><img src={appStore} alt="App Store" className="cta-button1" /></a>
//                                 <a href="https://play.google.com/store/apps/details?id=com.libyashop.ebuyer&hl=en&gl=US&fbclid=IwZXh0bgNhZW0CMTAAAR2SpE-A5UAatqNtD4DTbQ8CNj72Klku9_bX00-_LGDJFtQ9oXvFcP-Hgp8_aem_raPF6CXz_pXuQtC-tVX3sQ"><img src={googlePlay} alt="Google Play" className="cta-button2" /></a>
//                                 </div>
//                         </>
//                     ) : (
//                         <>
//                             <div className={`text-section top-text ${langClass}`}>
//                                 <div className={`logo-cta ${langClass}`}>
//                                     <img src={logo1} alt="Logo" className="logo-img-shop" />
//                                 </div>
//                                 <Typography variant="h4" className='text-buyer'>{t.sectionFour2}</Typography>
//                                 <Typography variant="body1" className={`text-shops ${langClass}`}>
//                                     {t.paraSectionFour}
//                                 </Typography>
//                                     <div className={`cta ${langClass}`}>
//                                     <a href="https://apps.apple.com/ly/app/libyashop/id6477825495"><img src={appStore} alt="App Store" className="cta-button1" /></a>
//                                     <a href="https://play.google.com/store/apps/details?id=com.libyashop.ebuyer&hl=en&gl=US&fbclid=IwZXh0bgNhZW0CMTAAAR2SpE-A5UAatqNtD4DTbQ8CNj72Klku9_bX00-_LGDJFtQ9oXvFcP-Hgp8_aem_raPF6CXz_pXuQtC-tVX3sQ"><img src={googlePlay} alt="Google Play" className="cta-button2" /></a>
//                                     </div>
//                             </div>
//                             <div className="image-section special-img">
//                                 <img src={img1} alt="" />
//                             </div>
//                         </>
//                     )}
//                 </div>
//             </CardContent>
//         </Card>
//     );
// }

// export default SectionFour;
